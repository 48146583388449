.consultation-schedule{
  .consultation-card{
    border-radius: 4px;
    border: solid 1px var(--light-sky-blue);
    background-color: var(--white);
    display: inline-flex;
    width: 100%;
  
    .rectangle {
      width: 4px;
      height: auto;
    }

    .row-avatar {
      display: flex;
      align-items: center;
      .doc-avatar {
        width: 55px;
        height: 55px;
      }
    }
  
  
    .info{
      padding-left: 16px;
      width: 99%;
      
      .cols-info {
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        vertical-align: middle;
      }

      .cols-info:not(:first-child) {
        justify-content: center;
      }
  
      .initials{
        border-radius: 50%;
        width: 55px;
        height: 55px;
        text-align: center;
        color: white;
        padding-top: 18px;
      }
  
      .doc-info{
        display: flex;
        align-items: center;
      
        .org-name{
          font-size: 12px;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--steel);
        }
        .doc-name{
          font-size: 16px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: var(--dark-blue-grey);
        }
      }
    }
  
    .consultation-info{
      .cause{
        padding: 3px 6px 4px 8px;
        border-radius: 11px;
        background-color: #d9ebff;
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #0176fe;
      }
    
      .btn{
        font-size: 12px;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
      }
    }

    .date{
      font-size: 12px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      margin-top: 18px;
      color: var(--steel);
    }
  }
}